
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CFormInputText from "@/components/form/input-text.vue";
import PCalendar from "primevue/calendar";

import { historicStatus } from "@/utils";
import { formatDateEn } from "@/utils/formatters";
import { useFilters } from "@/utils/mixins";

const VAppointmentFilter = defineComponent({
  name: "VAppointmentFilter",
  components: { CFormInputText, PCalendar },
  setup() {
    useHead({ title: "Filtrar agendamentos | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasAnyFilters } = useFilters();

    const filters = reactive({
      nm_paciente: route.query.nm_paciente || undefined,
      dt_inicial: route.query.dt_inicial ? new Date(String(route.query.dt_inicial).replaceAll("-", "/")) : undefined,
      dt_final: route.query.dt_final ? new Date(String(route.query.dt_final).replaceAll("-", "/")) : undefined,
    });
    const loading = reactive({ submit: false });

    async function applyFilters() {
      const dt_inicial = filters.dt_inicial ? formatDateEn(String(filters.dt_inicial)) : undefined;
      const dt_final = filters.dt_final ? formatDateEn(String(filters.dt_final)) : undefined;
      const ie_status = route.query.ie_status?.toString();

      router.replace({ query: { ...route.query, ...filters, dt_inicial, dt_final } });

      loading.submit = true;
      await store.dispatch("searchAppointments", {
        search: { ...filters, dt_inicial, dt_final, ie_status },
        isPrimary: true,
      });
      loading.submit = false;
    }

    function resetFilters() {
      filters.nm_paciente = undefined;
      filters.dt_inicial = undefined;
      filters.dt_final = undefined;

      applyFilters();
    }

    function handleClose() {
      router.replace({ name: "appointment", query: route.query });
    }

    return {
      ...{ filters, loading, hasAnyFilters, historicStatus },
      ...{ applyFilters, resetFilters, handleClose },
    };
  },
});

export default VAppointmentFilter;
